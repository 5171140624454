<template>
  <v-card v-if="!isNativeApp()" rounded flat class="transparent pb-2 mt-6">
    <v-sheet rounded class="transparent px-0 my-2" elevation="">
      <v-row justify="center">
        <v-col cols="12" md="6" align="center">
          <a :href="googlePlayLink" title="Get BFitr For Android">
            <v-sheet rounded class="transparent charcoalTile link pa-4 mx-2">
              <v-img
                :src="googlePlay"
                :width="isPhone ? '60px' : '90px'"
                contain
                alt="Get BFitr For Android"
              />

              <v-card-text
                :class="textSize + ' paper--text pb-0 px-2 text-center'"
              >
                Android
              </v-card-text>
              <v-card-text class="silver--text pb-0 px-2">
                Get BFIT(r) for <strong>Android</strong>
              </v-card-text>
            </v-sheet>
          </a>
        </v-col>
        <v-col cols="12" md="6" align="center">
          <a :href="appStoreLink" title="Get BFitr For IOS">
            <v-sheet rounded class="transparent charcoalTile pa-4 mx-2">
              <v-img
                :src="appStore"
                :width="isPhone ? '60px' : '90px'"
                contain
                alt="Get BFitr For IOS"
              />

              <v-card-text
                :class="textSize + ' paper--text pb-0 px-2 text-center'"
              >
                IOS
              </v-card-text>
              <v-card-text class="silver--text pb-0 px-2">
                Get BFIT(r) for <strong>IOS</strong>
              </v-card-text>
            </v-sheet>
          </a>
        </v-col>
      </v-row>
    </v-sheet>
    <v-sheet
      rounded
      class="charcoal px-2 my-2"
      elevation="2"
      :style="charcoalTile"
    >
      <v-card-subtitle :class="textSizeSmall + ' paper--text pt-5 pb-0 px-2'">
        In The Browser
      </v-card-subtitle>
      <v-card-text class="silver--text pt-0 px-2">
        BFitr features are optimized for flawless performance on your favorite
        browser! Just access
        <a href="https://bfit.fit" class="progressActive--text" title="BFit">
          https://bfit.fit </a
        >, log in, and enjoy the seamless functionality.
      </v-card-text>
    </v-sheet>
  </v-card>
</template>
<script>
import util from '@/mixins/util.js'
export default {
  mixins: [util],
  data: () => ({
    appStore: null,
    googlePlay: null
  }),
  beforeMount() {
    this.appStore = require('@/assets/AppStore.png')
    this.googlePlay = require('@/assets/GooglePlay.png')
  }
}
</script>
